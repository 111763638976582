var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',[_c('div',{staticClass:"highlight-body showClasses-rut"},[_c('div',{staticClass:"page-container md-layout-column"},[_c('div',[_c('div',{staticClass:"view-home__part5 w-100 h-100 show-courses__body"},[_c('div',{staticClass:"container-fluid size"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('video-player',{ref:"previewplayer",staticClass:"videoplayer",attrs:{"options":_vm.videoOptions}})],1),_c('div',{staticClass:"col-md-4 col-refactoring-boxs show-courses__info-instructor px-0 show-videos-class-in-desktop"},[_c('div',{staticClass:"show-courses__card-instructor"},[_c('div',{staticClass:"row show-courses__info-descrip-instructor mx-1"},[_c('div',{staticClass:"col-lg-3 col-md-12 col-12 px-0"},[_c('div',{staticClass:"p-2 justify-content-center text-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"img-fluid rounded-circle object-fit-cover",staticStyle:{"width":"70px","height":"70px","object-fit":"cover"},attrs:{"src":_vm.getInstructorAvatar(_vm.getClass)
                                ? _vm.getInstructorAvatar(_vm.getClass)
                                : '@/assets/images/logo/placeholder.png',"alt":"Card image cap"}})])])]),_c('div',{staticClass:"col-lg-9 col-sm-12 pl-2 pr-2"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"d-flex text-left text-uppercase show-courses__text pl-0",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.getClass.title)+" ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.getClass && _vm.getClass.instructors.length)?_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" Por "),_vm._l((_vm.getClass.instructors),function(instructor,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/instructores/instructor/${
                                      instructor.id
                                    }/${_vm.getInstructorSlug(instructor)}`}},[_vm._v(" "+_vm._s(instructor.firstname)+" "+_vm._s(instructor.lastname)+" ")]),(
                                      index !==
                                      _vm.getClass.instructors.length - 1
                                    )?_c('span',[_vm._v(", ")]):_vm._e()],1)})],2):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},_vm._l((_vm.getClass.categories),function(category,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/explorar/categoria/${category.slug}`}},[_vm._v(" "+_vm._s(category.title)+" ")]),(
                                      index !== _vm.getClass.categories.length - 1
                                    )?_c('span',[_vm._v(", ")]):_vm._e()],1)}),0)])])])])])])]),_c('div',{staticClass:"padding-videos"},[_c('div',{staticClass:"show-courses__videos"},[_c('badger-accordion',{ref:"badgerAccordion",staticClass:"accordion course-container__nav__chapter-collapse pt-4",attrs:{"icons":{
                        opened: `<i class='fas fa-angle-up'></i>`,
                        closed: `<i class='fas fa-angle-down'></i>`,
                      }}},[_c('div',{staticClass:"mb-4"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":`/clase/${_vm.getClass.slug}`}},[_c('div',{staticClass:"card",staticStyle:{"margin-bottom":"0 !important","margin-top":"-10px !important","cursor":"pointer","background":"black"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-mobile-img col-5 col-md-mobile col-md-5 pr-0",staticStyle:{"background":"#000"}},[_c('div',{staticStyle:{"border-radius":"6px","overflow":"hidden"}},[_c('img',{staticClass:"img-see-course",staticStyle:{"padding":"0"},attrs:{"src":_vm.getClass.video
                                        ? _vm.getClass.poster.url
                                        : _vm.getClass.poster.url,"alt":"Card image cap"}}),_c('div',{staticClass:"card-img-overlay timeLesson d-flex justify-content-between"},[_c('div',{staticClass:"details-play"},[_c('div')]),_c('a',{staticClass:"btn duration-style btn-sm text-right"},[_vm._v(_vm._s(_vm.formatTime(_vm.getClass)))])])])]),_c('div',{staticClass:"col-mobile-title col-7 col-md-7 show-courses__info-videos"},[_c('h5',{staticClass:"text-left show-courses__text text-mobile-descript text-decoration-none"},[_vm._v(" "+_vm._s(_vm.getClass.title)+" ")])])])])])],1)])],1)])])])])]),_c('div',{staticClass:"view-home__part5 show-courses__mobile-container",staticStyle:{"text-align":"start !important"}},[_c('div',{staticClass:"container-fluid size"},[_c('class-detail-preview',{attrs:{"data":_vm.getClass}})],1)])])])])]):_c('loading-panel',{attrs:{"title":"Cargando vista previa..."}})
}
var staticRenderFns = []

export { render, staticRenderFns }