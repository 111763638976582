var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8 mb-2 text-left px-3 desk-title"},[_c('h2',{staticStyle:{"color":"#b7227e"}},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])]),_vm._m(0),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"px-0 pt-2 content-text-data",domProps:{"innerHTML":_vm._s(_vm.data.content)}})]),_c('div',{staticClass:"col-12 row container-buttons-see-course"},_vm._l((_vm.data.files),function(file){return _c('a',{key:file.id,staticStyle:{"text-decoration":"none"},attrs:{"href":file.url,"target":"_blank"}},[_vm._m(1,true)])}),0),_c('div',{staticClass:"col-sm-12 mx-auto mx-lg-0 text-lg-left pl-lg-0 text-center"},[((_vm.data.products || []).length)?_c('button',{staticClass:"btn show-courses__btn-products text-white ml-lg-3 mt-2 mb-3 font-button-product",on:{"click":function($event){_vm.showProducts = !_vm.showProducts}}},[_c('i',{staticClass:"fas fa-shopping-cart"}),_vm._v(" PRODUCTOS DESTACADOS ")]):_vm._e()]),(_vm.showProducts)?_c('div',{staticClass:"col-12 mt-4 cards-instructors"},[_c('carousel',{attrs:{"navigation-enabled":true,"per-page":2,"per-page-custom":[
        [576, 3],
        [992, 4],
        [1200, 5],
        [1400, 6],
      ],"scroll-per-page":false,"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.data.products),function(product){return _c('slide',{key:product.id,staticClass:"pr-4"},[_c('a',{attrs:{"href":product.url,"target":"_blank"}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"borderless w-100 size-img-product show-courses__img-products img-shadow-none",attrs:{"alt":product.title,"src":product.poster.url}}),_c('div',{staticClass:"card-img-overlay text-right py-2 px-2 mt-1"},[_c('md-avatar',{staticClass:"button-price-circle-product"},[_c('span',{staticClass:"button-price-product-text"},[_vm._v("$"+_vm._s(product.price))])])],1),_c('h4',{staticClass:"text-uppercase text-decoration-none py-3 px-2 product-title-text"},[_vm._v(" "+_vm._s(product.title)+" ")])])])])}),1)],1):_vm._e(),_c('div',{staticClass:"row show-courses__info-descrip-instructor mx-1 mt-4 display-in-mobile-only w-100"},[_c('div',{staticClass:"col-lg-3 col-md-12 col-12 px-0"},[_c('div',{staticClass:"p-2 justify-content-center text-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"img-fluid rounded-circle object-fit-cover",staticStyle:{"width":"70px","height":"70px","object-fit":"cover"},attrs:{"src":_vm.getInstructorAvatar(_vm.getClass)
                ? _vm.getInstructorAvatar(_vm.getClass)
                : '@/assets/images/logo/placeholder.png',"alt":"Card image cap"}})])])]),_c('div',{staticClass:"col-lg-9 col-sm-12 pl-2 pr-2"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"d-flex text-left text-uppercase show-courses__text pl-0",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.getClass.title)+" ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.getClass && _vm.getClass.instructors.length)?_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" Por "),_vm._l((_vm.getClass.instructors),function(instructor,index){return _c('span',{key:index},[_c('router-link',{staticClass:"text-white-card",attrs:{"to":`/instructores/instructor/${
                      instructor.id
                    }/${_vm.getInstructorSlug(instructor)}`}},[_vm._v(" "+_vm._s(instructor.firstname)+" "+_vm._s(instructor.lastname)+" ")]),(index !== _vm.getClass.instructors.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()],1)})],2):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},_vm._l((_vm.getClass.categories),function(category,index){return _c('span',{key:index},[_c('router-link',{staticClass:"text-white-card",attrs:{"to":`/explorar/categoria/${category.slug}`}},[_vm._v(" "+_vm._s(category.title)+" ")]),(index !== _vm.getClass.categories.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()],1)}),0)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 mb-2 text-right"},[_c('h2',{staticClass:"show-courses__share-fav"},[_c('i',{staticClass:"pr-3 fas fa-heart",staticStyle:{"cursor":"pointer"}}),_c('span',{staticClass:"share-arrow-flex"},[_c('i',{staticClass:"fas fa-share pr-2"}),_c('span',{staticClass:"share"},[_vm._v("COMPARTIR")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn button-view-all-instructors mt-4 mr-3 d-flex show-courses__btn-download"},[_c('i',{staticClass:"far fa-arrow-alt-circle-down"}),_vm._v(" DESCARGAR PDF ")])
}]

export { render, staticRenderFns }