<template>
  <div v-if="!isLoading">
    <div class="highlight-body showClasses-rut">
      <div class="page-container md-layout-column">
        <div>
          <div class="view-home__part5 w-100 h-100 show-courses__body">
            <div class="container-fluid size">
              <div class="row">
                <div class="col-md-8">
                  <video-player
                    ref="previewplayer"
                    :options="videoOptions"
                    class="videoplayer"
                  />
                </div>
                <div
                  class="col-md-4 col-refactoring-boxs show-courses__info-instructor px-0 show-videos-class-in-desktop"
                >
                  <div class="show-courses__card-instructor">
                    <div class="row show-courses__info-descrip-instructor mx-1">
                      <!-- Foto -->
                      <div class="col-lg-3 col-md-12 col-12 px-0">
                        <div class="p-2 justify-content-center text-center">
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <img
                              :src="
                                getInstructorAvatar(getClass)
                                  ? getInstructorAvatar(getClass)
                                  : '@/assets/images/logo/placeholder.png'
                              "
                              alt="Card image cap"
                              class="img-fluid rounded-circle object-fit-cover"
                              style="
                                width: 70px;
                                height: 70px;
                                object-fit: cover;
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Nombre de la Clase -->

                      <div class="col-lg-9 col-sm-12 pl-2 pr-2">
                        <div class="card-body">
                          <h5
                            class="d-flex text-left text-uppercase show-courses__text pl-0"
                            style="font-weight: 700"
                          >
                            {{ getClass.title }}
                          </h5>

                          <div class="d-flex flex-row" style="max-width: 300px">
                            <div class="row">
                              <div class="col-12">
                                <h5
                                  v-if="getClass && getClass.instructors.length"
                                  class="text-uppercase show-courses__text text-left"
                                  style="font-size: 12px"
                                >
                                  Por
                                  <span
                                    v-for="(
                                      instructor, index
                                    ) in getClass.instructors"
                                    :key="index"
                                  >
                                    <router-link
                                      :to="`/instructores/instructor/${
                                        instructor.id
                                      }/${getInstructorSlug(instructor)}`"
                                      style="text-decoration: none"
                                    >
                                      {{ instructor.firstname }}
                                      {{ instructor.lastname }}
                                    </router-link>
                                    <span
                                      v-if="
                                        index !==
                                        getClass.instructors.length - 1
                                      "
                                      >,
                                    </span>
                                  </span>
                                </h5>
                              </div>

                              <!-- Categorias -->
                              <div class="col-12">
                                <h5
                                  class="text-uppercase show-courses__text text-left"
                                  style="font-size: 12px"
                                >
                                  <span
                                    v-for="(
                                      category, index
                                    ) in getClass.categories"
                                    :key="index"
                                  >
                                    <router-link
                                      :to="`/explorar/categoria/${category.slug}`"
                                      style="text-decoration: none"
                                    >
                                      {{ category.title }}
                                    </router-link>
                                    <span
                                      v-if="
                                        index !== getClass.categories.length - 1
                                      "
                                      >,
                                    </span>
                                  </span>
                                </h5>
                              </div>
                              <!-- Categorias -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="padding-videos">
                    <div class="show-courses__videos">
                      <badger-accordion
                        ref="badgerAccordion"
                        :icons="{
                          opened: `<i class='fas fa-angle-up'></i>`,
                          closed: `<i class='fas fa-angle-down'></i>`,
                        }"
                        class="accordion course-container__nav__chapter-collapse pt-4"
                      >
                        <div class="mb-4">
                          <router-link
                            :to="`/clase/${getClass.slug}`"
                            class="text-decoration-none"
                          >
                            <div
                              class="card"
                              style="
                                margin-bottom: 0 !important;
                                margin-top: -10px !important;
                                cursor: pointer;
                                background: black;
                              "
                            >
                              <div class="row">
                                <div
                                  class="col-mobile-img col-5 col-md-mobile col-md-5 pr-0"
                                  style="background: #000"
                                >
                                  <div
                                    style="border-radius: 6px; overflow: hidden"
                                  >
                                    <img
                                      :src="
                                        getClass.video
                                          ? getClass.poster.url
                                          : getClass.poster.url
                                      "
                                      alt="Card image cap"
                                      class="img-see-course"
                                      style="padding: 0"
                                    />
                                    <div
                                      class="card-img-overlay timeLesson d-flex justify-content-between"
                                    >
                                      <div class="details-play">
                                        <div></div>
                                      </div>
                                      <a
                                        class="btn duration-style btn-sm text-right"
                                        >{{ formatTime(getClass) }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-mobile-title col-7 col-md-7 show-courses__info-videos"
                                >
                                  <h5
                                    class="text-left show-courses__text text-mobile-descript text-decoration-none"
                                  >
                                    {{ getClass.title }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </badger-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="view-home__part5 show-courses__mobile-container"
            style="text-align: start !important"
          >
            <div class="container-fluid size">
              <class-detail-preview :data="getClass" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading-panel v-else title="Cargando vista previa..."></loading-panel>
</template>

<script>
import { FETCH_CLASS } from '../../../../store/actions/classes';
import LoadingPanel from '../../../../components/LoadingPanel.vue';
import { mapGetters } from 'vuex';
import VideoPlayer from '../../../../components/VideoPlayer.vue';
import 'video.js/dist/video-js.css';

import ClassDetailPreview from './components/ClassDetailPreview.vue';
import format from 'format-duration';
import { BadgerAccordion } from 'vue-badger-accordion';

export default {
  name: 'ClassPreview',
  components: {
    LoadingPanel,
    VideoPlayer,
    ClassDetailPreview,
    BadgerAccordion,
  },

  data() {
    return {
      isLoading: true,
      videoOptions: {},
      isError: null,
    };
  },
  computed: {
    ...mapGetters(['getClass']),
  },
  watch: {
    getClass: function (newValue) {
      if (newValue) {
        this.videoOptions = {
          autoplay: false,
          controls: true,
          sources: [
            {
              src: newValue.video ? newValue.video.url : '',
              type: 'application/x-mpegURL',
            },
          ],
          poster: newValue.video ? newValue.video.thumbnail : '',
          fluid: true,
          aspectRatio: '16:9',
          hls: true,
          plugins: {
            airPlay: {
              addButtonToControlBar: true, // defaults to `true`
            },
          },
        };
      } else {
        this.videoOptions = {};
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchClass();
  },
  methods: {
    async fetchClass() {
      await this.$store.dispatch(FETCH_CLASS, this.$route.params.id);
    },
    formatTime(_class) {
      const duration = _class.video ? _class.video.duration : 0;
      return format(duration * 1000);
    },
    getClassContent(_class) {
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = _class.content;
      return htmlObject.textContent;
    },
    getInstructorAvatar(_class) {
      if (_class && _class.instructors && _class.instructors.length) {
        return _class.instructors[0] && _class.instructors[0].avatar
          ? _class.instructors[0].avatar.url
          : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
      }
      return 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
    },
    getInstructorSlug(instructor) {
      const fullname = `${instructor.firstname ?? ''} ${
        instructor.lastname ?? ''
      }`;
      return fullname.toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 0.5rem;
}

.show-courses__info-descrip-instructor {
  border-radius: 8px !important;
}
.md-theme-default a:not(.md-button) {
  color: #fff;
}
.show-courses__videos {
  overflow: hidden !important;
}

.vjs-poster {
  background-size: 100px !important;
}

@media screen and (max-width: 992px) {
  .show-courses__img-instructor img {
    width: 90px !important;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .show-classes_video {
    padding: 60px 0px 0px 0px !important;
  }

  .show-videos-class-in-desktop {
    display: none;
  }
}
</style>
