<template>
  <div class="row">
    <div class="col-8 mb-2 text-left px-3 desk-title">
      <h2 style="color: #b7227e">
        {{ data.title }}
      </h2>
    </div>

    <div class="col-4 mb-2 text-right">
      <h2 class="show-courses__share-fav">
        <i class="pr-3 fas fa-heart" style="cursor: pointer"></i>
        <span class="share-arrow-flex">
          <i class="fas fa-share pr-2"></i><span class="share">COMPARTIR</span>
        </span>
      </h2>
    </div>

    <div class="col-12">
      <div class="px-0 pt-2 content-text-data" v-html="data.content"></div>
    </div>

    <div class="col-12 row container-buttons-see-course">
      <a
        v-for="file in data.files"
        :key="file.id"
        :href="file.url"
        style="text-decoration: none"
        target="_blank"
      >
        <button
          class="btn button-view-all-instructors mt-4 mr-3 d-flex show-courses__btn-download"
        >
          <i class="far fa-arrow-alt-circle-down"></i>
          DESCARGAR PDF
        </button>
      </a>
    </div>
    <div class="col-sm-12 mx-auto mx-lg-0 text-lg-left pl-lg-0 text-center">
      <button
        v-if="(data.products || []).length"
        class="btn show-courses__btn-products text-white ml-lg-3 mt-2 mb-3 font-button-product"
        @click="showProducts = !showProducts"
      >
        <i class="fas fa-shopping-cart"></i> PRODUCTOS DESTACADOS
      </button>
    </div>
    <div v-if="showProducts" class="col-12 mt-4 cards-instructors">
      <carousel
        :navigation-enabled="true"
        :per-page="2"
        :per-page-custom="[
          [576, 3],
          [992, 4],
          [1200, 5],
          [1400, 6],
        ]"
        :scroll-per-page="false"
        :speed="500"
        navigation-next-label="<i class='fas fa-chevron-right'></i>"
        navigation-prev-label="<i class='fas fa-chevron-left'></i>"
      >
        <slide v-for="product in data.products" :key="product.id" class="pr-4">
          <a :href="product.url" target="_blank">
            <div class="card">
              <img
                :alt="product.title"
                :src="product.poster.url"
                class="borderless w-100 size-img-product show-courses__img-products img-shadow-none"
              />

              <div class="card-img-overlay text-right py-2 px-2 mt-1">
                <md-avatar class="button-price-circle-product">
                  <span class="button-price-product-text"
                    >${{ product.price }}</span
                  >
                </md-avatar>
              </div>
              <h4
                class="text-uppercase text-decoration-none py-3 px-2 product-title-text"
              >
                {{ product.title }}
              </h4>
            </div>
          </a>
        </slide>
      </carousel>
    </div>

    <div
      class="row show-courses__info-descrip-instructor mx-1 mt-4 display-in-mobile-only w-100"
    >
      <!-- Foto -->

      <div class="col-lg-3 col-md-12 col-12 px-0">
        <div class="p-2 justify-content-center text-center">
          <div class="d-flex align-items-center justify-content-center">
            <img
              :src="
                getInstructorAvatar(getClass)
                  ? getInstructorAvatar(getClass)
                  : '@/assets/images/logo/placeholder.png'
              "
              alt="Card image cap"
              class="img-fluid rounded-circle object-fit-cover"
              style="width: 70px; height: 70px; object-fit: cover"
            />
          </div>
        </div>
      </div>
      <!-- Nombre de la Clase -->

      <div class="col-lg-9 col-sm-12 pl-2 pr-2">
        <div class="card-body">
          <h5
            class="d-flex text-left text-uppercase show-courses__text pl-0"
            style="font-weight: 700"
          >
            {{ getClass.title }}
          </h5>

          <div class="d-flex flex-row" style="max-width: 300px">
            <div class="row">
              <div class="col-12">
                <h5
                  v-if="getClass && getClass.instructors.length"
                  class="text-uppercase show-courses__text text-left"
                  style="font-size: 12px"
                >
                  Por
                  <span
                    v-for="(instructor, index) in getClass.instructors"
                    :key="index"
                  >
                    <router-link
                      :to="`/instructores/instructor/${
                        instructor.id
                      }/${getInstructorSlug(instructor)}`"
                      class="text-white-card"
                    >
                      {{ instructor.firstname }}
                      {{ instructor.lastname }}
                    </router-link>
                    <span v-if="index !== getClass.instructors.length - 1"
                      >,
                    </span>
                  </span>
                </h5>
              </div>

              <!-- Categorias -->
              <div class="col-12">
                <h5
                  class="text-uppercase show-courses__text text-left"
                  style="font-size: 12px"
                >
                  <span
                    v-for="(category, index) in getClass.categories"
                    :key="index"
                  >
                    <router-link
                      :to="`/explorar/categoria/${category.slug}`"
                      class="text-white-card"
                    >
                      {{ category.title }}
                    </router-link>
                    <span v-if="index !== getClass.categories.length - 1"
                      >,
                    </span>
                  </span>
                </h5>
              </div>
              <!-- Categorias -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';

export default {
  name: 'ClassDetailPreview',
  components: { Carousel, Slide },

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: '',
          content: '',
          files: [],
          products: [],
          instructors: [],
          categories: [],
        };
      },
    },
  },
  data() {
    return {
      showProducts: false,
      isError: null,
    };
  },
  computed: {
    ...mapGetters(['getClass']),
  },
  methods: {
    getInstructorAvatar(_class) {
      if (_class && _class.instructors && _class.instructors.length) {
        return _class.instructors[0] && _class.instructors[0].avatar
          ? _class.instructors[0].avatar.url
          : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
      }
      return 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
    },
    getInstructorSlug(instructor) {
      const fullname = `${instructor.firstname ?? ''} ${
        instructor.lastname ?? ''
      }`;
      return fullname.toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .show-courses__body {
    padding: 10px 0px !important;
  }
}
.text-white-card {
  color: #fff !important;
  text-decoration: none;
}
.view-home__part5 p {
  margin: 0px !important;
  padding: 0px !important;
}

.sharebutton,
.sharebutton i {
  font-size: 14px;
}
.show-courses__share-fav i {
  font-size: 17px;
}

.sharebutton:hover {
  color: #b7227e;
  cursor: pointer;
}

.sharebutton:active {
  color: #b7227e;
}

.share-mobile-display {
  display: none;
}

.text-description-data .text-description p {
  padding: 0px !important;
  margin: 0px !important;
}

.highlight-body h2::selection,
.highlight-body h3::selection,
.highlight-body span::selection,
.highlight-body label::selection,
.highlight-body small::selection,
.highlight-body img::selection,
.highlight-body a::selection {
  background-color: #b9207e !important;
}

.show-videos-class-in-mobile {
  display: none;
}
.show-courses__info-descrip-instructor {
  border-radius: 8px;
}
.display-in-mobile-only {
  display: none;
}

@media screen and (max-width: 992px) {
  .share-mobile-display {
    display: block;
  }
  .share-hidde-mobile {
    display: none;
  }
  .show-videos-class-in-mobile {
    display: block;
  }
  .display-in-mobile-only {
    display: block;
  }
}
a:hover {
  text-decoration: none !important;
}
.img-shadow-none {
  box-shadow: none !important;
}

.font-button-product,
.fas.fa-shopping-cart {
  font-size: 12px !important;
}

.show-courses__img-products {
  border: none !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 0px !important;
}

.size-img-product {
  height: 200px !important;
  width: 100%;
  max-width: 250px !important;
  max-height: 250px !important;
  border: none !important;
  border-radius: 10px 10px 0px 0px !important;
  margin-top: 0px !important;
}

.card {
  height: 280px;
  border-radius: 10px;
  border: 1px solid #ddd;
  margin-bottom: 0px;
  margin-right: 0px;
  max-width: 250px !important;
  min-width: 250px !important;
}
.product-title-text {
  max-width: 250px !important;
  display: relative;
}
.button-price-circle-product {
  background-color: #b7227e !important;
  width: 35px;
  min-width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: none !important;
}

.button-price-product-text {
  border-radius: 50%;
  color: #fff !important;
  padding: 0px;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01rem;
  border: none;
  padding-bottom: 1px;
}

.share-arrow-flex {
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos */
}

.share-icon {
  margin-right: 5px; /* Espacio entre el icono y el texto */
}
</style>
